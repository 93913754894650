body::-webkit-scrollbar {
    width: 0.3em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffc007
}

body::-webkit-scrollbar-thumb {
    background-color: #364FEC;
    outline: 1px solid rgba(#364FEC, 0.2);
}

.widget-container {
    width: 100%;
    height: 100vh;
    scrollbar-width: thin;

    .default-view-page {
        width: 100%;
        height: 100%;
        padding: 1em;
        overflow-y: auto;
        flex-direction: column;
        scroll-behavior: auto;


        .close-button-container {
            z-index: 10;
            width: 100%;
            max-width: 500px;

            .close-button {
                border: 0;
                width: 2em;
                height: 2em;
                font-size: 1.5em;
                border-radius: 50%;
                background-color: #fff;
                margin-right: -0.5em;
                margin-bottom: -1em;
            }
        }

        iframe,
        .widget {
            width: 100%;
            height: 100%;
            flex-grow: 1;
            display: block;
            max-width: 500px;
            max-height: 600px;
            overflow-y: auto;
            overflow: hidden;
            border-radius: 0.5em;
            background-color: white;
        }
    }

}