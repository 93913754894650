@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,600;0,900;1,200;1,400;1,500;1,600&display=swap');

* {
  // border: solid 1px red;
}

html {
  font-size: 12px
}

body {
  margin: 0;
  max-height: 50px;
  overflow-x: hidden;
  background-color: rgba(#C4C4C4, 0.6);
}

.text-bg-warning {
  color: #333333;
  background-color: #ffc007;
  font-weight: 600;
}

/* Modal */
.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Set z-index for modal overlay */
}

.modal-content {
  width: 100%;
  min-height: 100vh;
  padding: 1em;
  background-color: #fff;
}

/* Responsive styles */
@media (min-width: 767px) {
  .modal-body.p-xl-4 {
    padding: 6em;
  }

  .p-md-3 {
    padding: 3rem !important;
  }
}


@media (max-width: 767px) {
  .modal-content {
    /* Full width on small screens */
  }
}

/* End of Modal */


/*  BTN CLOSE */
.modal-header .btn-close {
  margin: initial;
}

/* END OF BTN CLOSE */


/* LOADER */
.loader {
  --c: #364FEC;
  width: 7em;
  --n: 8;
  --b: 1em;
  --g: 10deg;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 0.1em;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,
      #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
      #0000 calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
    radial-gradient(farthest-side, #0000 calc(98% - var(--b)), #000 calc(100% - var(--b)));
  mask:
    repeating-conic-gradient(#0000 0deg,
      #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
      #0000 calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
    radial-gradient(farthest-side, #0000 calc(98% - var(--b)), #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
  margin: 0 auto;
}

@keyframes load {
  to {
    transform: rotate(1turn)
  }
}

/* END OF LOADER */

h2 {
  font-size: 1.5em;
}

span.invalid {
  color: red;
  font-size: small;
  font-family: 'Poppins';
}

.fw-bold {
  font-weight: 500 !important;
}

.first-hr {
  height: 3px !important;
  background-color: #3e64ff;
  border: none;
  opacity: 1;
}

.second-hr {
  height: 2px;
  background-color: #3e64ff;
  border: none;
  opacity: 1;
}

.react-select__control {
  padding: 0.5em;
}


.form-control {
  height: 3em;
  background: #fff;
  color: rgba(0, 0, 0, .8) !important;
  font-size: 1.1em;
  border-radius: 5px;
  box-shadow: none;
  /* border: 1px solid rgba(0, 0, 0, .1); */
  border: 1px solid #e6e6e6;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  box-shadow: none;
  border: 1px solid #3e64ff;
}

a {
  transition: 0.3s all ease;
  color: #3e64ff;
  text-decoration: none;
  font-family: 'Poppins' !important;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #333333 !important;
  /* opacity: 0.5; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #333333 !important;
  /* opacity: 0.5; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #333333 !important;
  /* opacity: 0.5; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333333 !important;
  /* opacity: 0.5; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #333333 !important;
  /* opacity: 0.5; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #333333 !important;
  /* opacity: 0.5; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}